(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/position-to-icon.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/position-to-icon.js');
"use strict";


const {
  gameTypes,
  rowValues
} = svs.racing.common.constants;
const getIconForValue = (rowValue, gameType, isWheel, windiv, isLegacyTopp5) => {
  switch (gameType) {
    case gameTypes.RX3IO:
    case gameTypes.RX2IO:
      switch (rowValue) {
        case rowValues.MISSING:
        case rowValues.INCORRECT:
        case rowValues.CORRECT_IRRELEVANT_AO:
        case rowValues.CORRECT:
          return 'close';
        case rowValues.CORRECT_IRRELEVANT_IO:
        case rowValues.CORRECT_IN_ORDER:
          return 'checkmark';
        case rowValues.NEUTRAL:
        default:
          return undefined;
      }
    case gameTypes.TOPP5:
      if (windiv === 2 && !isLegacyTopp5) {
        switch (rowValue) {
          case rowValues.MISSING:
          case rowValues.INCORRECT:
            return 'close';
          case rowValues.CORRECT_IRRELEVANT_IO:
          case rowValues.CORRECT_IRRELEVANT_AO:
          case rowValues.CORRECT_IN_ORDER:
          case rowValues.CORRECT:
            return 'checkmark';
          case rowValues.NEUTRAL:
          default:
            return 'help-outline';
        }
      } else {
        switch (rowValue) {
          case rowValues.MISSING:
          case rowValues.INCORRECT:
          case rowValues.CORRECT_IRRELEVANT_IO:
          case rowValues.CORRECT_IRRELEVANT_AO:
            return 'close';
          case rowValues.CORRECT_IN_ORDER:
          case rowValues.CORRECT:
            return 'checkmark';
          case rowValues.NEUTRAL:
          default:
            return 'help-outline';
        }
      }
    case gameTypes.TOPP2:
      switch (rowValue) {
        case rowValues.MISSING:
        case rowValues.INCORRECT:
          return 'close';
        case rowValues.CORRECT_IN_ORDER:
        case rowValues.CORRECT:
        case rowValues.CORRECT_IRRELEVANT_IO:
        case rowValues.CORRECT_IRRELEVANT_AO:
          return 'checkmark';
        case rowValues.NEUTRAL:
        default:
          return undefined;
      }
    case gameTypes.RX2P:
    case gameTypes.RX2P4:
      if (isWheel) {
        switch (rowValue) {
          case rowValues.MISSING:
          case rowValues.INCORRECT:
          case rowValues.CORRECT_IRRELEVANT_IO:
          case rowValues.CORRECT_IRRELEVANT_AO:
            return 'close';
          case rowValues.CORRECT_IN_ORDER:
          case rowValues.CORRECT:
            return 'checkmark';
          case rowValues.NEUTRAL:
          default:
            return undefined;
        }
      } else {
        switch (rowValue) {
          case rowValues.MISSING:
          case rowValues.INCORRECT:
            return 'close';
          case rowValues.CORRECT_IN_ORDER:
          case rowValues.CORRECT:
          case rowValues.CORRECT_IRRELEVANT_IO:
          case rowValues.CORRECT_IRRELEVANT_AO:
            return 'checkmark';
          case rowValues.NEUTRAL:
          default:
            return undefined;
        }
      }
    default:
      switch (rowValue) {
        case rowValues.MISSING:
        case rowValues.INCORRECT:
        case rowValues.CORRECT_IRRELEVANT_IO:
        case rowValues.CORRECT_IRRELEVANT_AO:
          return 'close';
        case rowValues.CORRECT_IN_ORDER:
        case rowValues.CORRECT:
          return 'checkmark';
        case rowValues.NEUTRAL:
        default:
          return undefined;
      }
  }
};
setGlobal('svs.components.racing.resultUtils.positionToIcon', {
  getIconForValue
});

 })(window);