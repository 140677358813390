(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/get-win-lists.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/get-win-lists.js');
"use strict";


const gameTypes = svs.racing.common.constants.gameTypes;
const {
  myGamingUtil,
  gameTypeWindivs
} = svs.components.racing.resultUtils;
const {
  format
} = svs.utils;
const groupWinsByWinDiv = wins => {
  const winDivs = [];
  const amounts = [];
  return wins.reduce((distinctWins, win) => {
    const qualifiesForNewWinGroup = !winDivs.includes(win.winDiv) || !amounts.includes(win.amount);
    if (qualifiesForNewWinGroup) {
      distinctWins.push(win);
      winDivs.push(win.winDiv);
      amounts.push(win.amount);
    }
    return distinctWins;
  }, []);
};
const getPlayerWinGroups = (wager, gameType, resultRowObject, sharesCount, isLegacyTopp5) => {
  const regularWins = [];
  const cancelledParticipantWins = [];
  const refunds = [];
  const resultRowString = resultRowObject ? resultRowObject.row.map(position => {
    if (position.length > 1) {
      return "(".concat(position.join(' & '), ")");
    }
    return position;
  }).join(' - ') : '';
  let sumWinAmount = 0;
  let sumNumWins = 0;
  groupWinsByWinDiv(wager.wins).forEach(win => {
    sumWinAmount += parseInt(win.amount, 10);
    sumNumWins += win.numWins;
    const sumParsedWinAmount = sumWinAmount * sharesCount;
    const parsedWinAmount = parseInt(win.amount, 10) * sharesCount;
    const cancelledWinDivIndex = gameTypeWindivs[gameType].cancelledWinDivIndex;
    if (cancelledWinDivIndex > -1 && win.winDiv >= cancelledWinDivIndex) {
      cancelledParticipantWins.push({
        amount: "".concat(format.Currency(parsedWinAmount / win.numWins), " kr"),
        correctNumberTxt: gameTypeWindivs[gameType].labels[win.winDiv]({
          winners: resultRowString,
          isLegacyTopp5
        }),
        type: 'Vinst',
        numWins: "(".concat(win.numWins, " st)"),
        winDiv: win.winDiv
      });
    } else if (win.winType === 1) {
      if (gameType === gameTypes.PLATS || gameType === gameTypes.RX2P) {
        if (wager.wins.length > 0) {
          regularWins.pop();
        }
        regularWins.push({
          amount: "".concat(format.Currency(sumParsedWinAmount), " kr"),
          correctNumberTxt: gameTypeWindivs[gameType].labels[win.winDiv]({
            winners: resultRowString,
            isLegacyTopp5
          }),
          type: 'Vinst',
          numWins: "(".concat(sumNumWins, " st)"),
          winDiv: win.winDiv
        });
      } else {
        regularWins.push({
          amount: "".concat(format.Currency(parsedWinAmount / win.numWins), " kr"),
          correctNumberTxt: gameTypeWindivs[gameType].labels[win.winDiv]({
            winners: resultRowString,
            isLegacyTopp5
          }),
          type: 'Vinst',
          numWins: "(".concat(win.numWins, " st)"),
          winDiv: win.winDiv
        });
      }
    } else if (win.winType === 2) {
      refunds.push({
        amount: "".concat(format.Currency(parsedWinAmount), " kr"),
        correctNumberTxt: 'Återbetalning',
        type: 'Återbetalning'
      });
    }
  });
  return {
    regularWins,
    cancelledParticipantWins,
    refunds
  };
};
const getWinLists = (wager, resultRowObject, sharesCount, isLegacyTopp5) => {
  const gameType = myGamingUtil.getWagerGameType(wager);
  const winLists = getPlayerWinGroups(wager, gameType, resultRowObject, sharesCount, isLegacyTopp5);
  const hasRegularWins = winLists.regularWins.length > 0;
  const hasCancelledParticipantWins = winLists.cancelledParticipantWins.length > 0;
  const hasRefunds = winLists.refunds.length > 0;
  return {
    winLists,
    hasRegularWins,
    hasCancelledParticipantWins,
    hasRefunds
  };
};
setGlobal('svs.components.racing.resultUtils.getWinLists', getWinLists);

 })(window);