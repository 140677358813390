(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/position-to-class.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/position-to-class.js');
"use strict";


const {
  gameTypes,
  rowValues
} = svs.racing.common.constants;
const getClassForValue = (rowValue, gameType, isWheel, windiv, isLegacyTopp5) => {
  if (isWheel) {
    switch (gameType) {
      case gameTypes.RX2P:
      case gameTypes.RX2P4:
      case gameTypes.TOPP5:
      case gameTypes.RX4IO:
      case gameTypes.RX3IOAO:
      case gameTypes.RX3AO:
      case gameTypes.TOPP2:
        {
          switch (rowValue) {
            case rowValues.MISSING:
              return 'missing';
            case rowValues.INCORRECT:
              return 'incorrect';
            case rowValues.CORRECT:
            case rowValues.CORRECT_IN_ORDER:
              return 'correct-in-order';
            case rowValues.CORRECT_IRRELEVANT_IO:
            case rowValues.CORRECT_IRRELEVANT_AO:
              return 'correct-irrelevant';
            case rowValues.NEUTRAL:
            default:
              return '';
          }
        }
      case gameTypes.RX2IO:
      case gameTypes.RX3IO:
        {
          switch (rowValue) {
            case rowValues.MISSING:
              return 'missing';
            case rowValues.INCORRECT:
            case rowValues.CORRECT_IRRELEVANT_AO:
            case rowValues.CORRECT:
              return 'incorrect';
            case rowValues.CORRECT_IRRELEVANT_IO:
            case rowValues.CORRECT_IN_ORDER:
              return 'correct-in-order';
            case rowValues.NEUTRAL:
            default:
              return '';
          }
        }
      default:
        switch (rowValue) {
          case rowValues.MISSING:
            return 'missing';
          case rowValues.INCORRECT:
            return 'incorrect';
          case rowValues.CORRECT_IN_ORDER:
          case rowValues.CORRECT:
          case rowValues.CORRECT_IRRELEVANT_IO:
          case rowValues.CORRECT_IRRELEVANT_AO:
            return 'correct-in-order';
          case rowValues.NEUTRAL:
          default:
            return '';
        }
    }
  } else {
    switch (gameType) {
      case gameTypes.TOPP5:
        {
          if (windiv === 2 && !isLegacyTopp5) {
            switch (rowValue) {
              case rowValues.MISSING:
                return 'missing';
              case rowValues.INCORRECT:
                return 'incorrect';
              case rowValues.CORRECT_IN_ORDER:
              case rowValues.CORRECT_IRRELEVANT_IO:
                return 'correct-in-order';
              case rowValues.CORRECT:
              case rowValues.CORRECT_IRRELEVANT_AO:
                return 'correct';
              case rowValues.NEUTRAL:
              default:
                return '';
            }
          } else {
            switch (rowValue) {
              case rowValues.MISSING:
                return 'missing';
              case rowValues.INCORRECT:
                return 'incorrect';
              case rowValues.CORRECT_IN_ORDER:
                return 'correct-in-order';
              case rowValues.CORRECT:
                return 'correct';
              case rowValues.CORRECT_IRRELEVANT_IO:
              case rowValues.CORRECT_IRRELEVANT_AO:
                return 'correct-irrelevant';
              case rowValues.NEUTRAL:
              default:
                return '';
            }
          }
        }
      case gameTypes.RX4IO:
      case gameTypes.RX3IOAO:
        {
          switch (rowValue) {
            case rowValues.MISSING:
              return 'missing';
            case rowValues.INCORRECT:
              return 'incorrect';
            case rowValues.CORRECT_IN_ORDER:
              return 'correct-in-order';
            case rowValues.CORRECT:
              return 'correct';
            case rowValues.CORRECT_IRRELEVANT_IO:
            case rowValues.CORRECT_IRRELEVANT_AO:
              return 'correct-irrelevant';
            case rowValues.NEUTRAL:
            default:
              return '';
          }
        }
      case gameTypes.RX2IO:
      case gameTypes.RX3IO:
        {
          switch (rowValue) {
            case rowValues.MISSING:
              return 'missing';
            case rowValues.INCORRECT:
            case rowValues.CORRECT_IRRELEVANT_AO:
            case rowValues.CORRECT:
              return 'incorrect';
            case rowValues.CORRECT_IRRELEVANT_IO:
            case rowValues.CORRECT_IN_ORDER:
              return 'correct-in-order';
            case rowValues.NEUTRAL:
            default:
              return '';
          }
        }
      default:
        switch (rowValue) {
          case rowValues.MISSING:
            return 'missing';
          case rowValues.INCORRECT:
            return 'incorrect';
          case rowValues.CORRECT_IN_ORDER:
          case rowValues.CORRECT:
          case rowValues.CORRECT_IRRELEVANT_IO:
          case rowValues.CORRECT_IRRELEVANT_AO:
            return 'correct-in-order';
          case rowValues.NEUTRAL:
          default:
            return '';
        }
    }
  }
};
setGlobal('svs.components.racing.resultUtils.positionToClass', {
  getClassForValue
});

 })(window);