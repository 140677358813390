(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/replacements.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/replacements.js');
"use strict";


function shiftValueToEnd(array, elementIndex) {
  if (elementIndex === array.length - 1) {
    return array;
  }
  for (let i = elementIndex; i < array.length - 1; i++) {
    const value = array[i];
    array[i] = array[i + 1];
    array[i + 1] = value;
  }
  return array;
}

function collapseRow(row) {
  for (let i = 0, cancelledCount = 0; i < row.length - cancelledCount; i++) {
    if (!row[i]) {
      shiftValueToEnd(row, i);
      cancelledCount++;
    }
  }
  return row;
}

function replaceInRow(rowParam, cancelled, reserve, preserveOrder) {
  const row = rowParam.slice();
  let firstCancelledIndex = -1;
  let cancelledCount = 0;
  for (let position = row.length - 1; position >= 0; position--) {
    const value = row[position];
    for (let cancelledIndex = 0; cancelledIndex < cancelled.length; cancelledIndex++) {
      const cancelledValue = cancelled[cancelledIndex];

      if (cancelledValue === value) {
        row[position] = undefined;
        if (!preserveOrder) {
          shiftValueToEnd(row, position);
        }

        firstCancelledIndex = position;
        cancelledCount++;
        break;
      }
    }
  }

  if (reserve && reserve > 0 && cancelledCount > 0 && row.indexOf(reserve) < 0) {
    if (preserveOrder) {
      row[firstCancelledIndex] = reserve;
    } else {
      row[row.length - cancelledCount] = reserve;
    }
  }
  return row;
}
function replaceInRows(rows, cancelled, reserve, preserveOrder) {
  const result = Array(rows.length);
  for (let i = 0; i < rows.length; i++) {
    result[i] = replaceInRow(rows[i], cancelled, reserve, preserveOrder);
  }
  return result;
}
setGlobal('svs.components.racing.resultUtils.replacements', {
  collapseRow,
  replaceInRow,
  replaceInRows
});

 })(window);