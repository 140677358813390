(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/wager-to-rows.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/wager-to-rows.js');
"use strict";


const {
  subProductIdToGameType,
  rowGenMode
} = svs.racing.common.constants;
const {
  gameTypeIsAnyOrder,
  gameTypeNumSelections
} = svs.racing.common.util;
const gameTypeWindivs = svs.components.racing.resultUtils.gameTypeWindivs;
const {
  combinations,
  permutations
} = svs.components.racing.resultUtils.combinatorics;
const buildUniqueRankRows = svs.racing.build_unique_rank_row.buildUniqueRankRows;
const {
  collapseRow,
  replaceInRow,
  replaceInRows
} = svs.components.racing.resultUtils.replacements;
const {
  getRowValues,
  getRowValuesNoResult,
  getRowWindiv,
  getOverallRowValue,
  getRowValuesWithIrrelevant,
  getNumTotalCorrectIrrelevant
} = svs.components.racing.resultUtils.valuation;
const {
  sort,
  byRow,
  bestRow
} = svs.components.racing.resultUtils.sorting;
const {
  gameTypeHasSmygen
} = svs.components.racing.resultUtils.myGamingUtil;
function posToBoardData(board) {
  const wagerRow = board.extras ? board.extras : board.rxRaces.map(x => x[0]);
  return wagerRow;
}
function noPosToBoardData(board) {
  return board.rxRaces[0].length > 0 ? board.rxRaces : board.rxRaces[1];
}
function mathPosToBoardData(board) {
  return board.rxRaces;
}

const buildCompleteRowObj = (rowIndex, row, partialRowObj, windiv, numTotalCorrectIrrelevant, isHighlighted, overallRowValue, hasResult, resultRowIndex) => ({
  key: "".concat(row.join('-'), "-").concat(rowIndex),
  row: [...row],
  rowValues: [...partialRowObj.rowValues],
  resultToRowIndices: hasResult ? [...partialRowObj.resultToRowIndices] : [],
  numCorrectInOrder: partialRowObj.numCorrectInOrder,
  numTotalCorrectInOrder: partialRowObj.numTotalCorrectInOrder,
  numCorrectAnyOrder: partialRowObj.numCorrectAnyOrder,
  numTotalCorrectAnyOrder: partialRowObj.numTotalCorrectAnyOrder,
  numTotalCorrectIrrelevant,
  actualRowLength: partialRowObj.actualRowLength,
  isHighlighted,
  windiv,
  overallRowValue,
  resultRowIndex,
  initialRowIndex: rowIndex
});
const wagerToRows = function (wager, resultRows, cancelledParticipants, wagerExamined, selectedView, isSmygen) {
  let isLegacyTopp5 = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : false;
  const bet = wager.bets[0];
  const board = bet.rxBoards[0];
  const gameType = subProductIdToGameType[bet.subProductId];
  const numSelections = gameTypeNumSelections(gameType);
  const isAnyOrderProduct = gameTypeIsAnyOrder(gameType);
  let isAnyOrderWager = false;
  const systemType = board.systemType[0];

  const replacementRequired = cancelledParticipants && cancelledParticipants.length > 0;
  let reserve = board.replacement ? board.replacement : undefined;
  if (replacementRequired && reserve) {
    reserve = cancelledParticipants.indexOf(reserve) > -1 ? undefined : reserve;
  }
  let wagerRowGenMode;
  let boardData;
  let rows;

  switch (systemType) {
    case 'RX_POS':
      boardData = posToBoardData(board);
      wagerRowGenMode = rowGenMode.COMBINATIONS;
      break;
    case 'RX_NOPOS':
      boardData = noPosToBoardData(board);
      if (Array.isArray(boardData[0])) {
        wagerRowGenMode = rowGenMode.WHEEL;
      } else if (isAnyOrderProduct) {
        wagerRowGenMode = rowGenMode.COMBINATIONS;
      } else {
        wagerRowGenMode = rowGenMode.PERMUTATIONS;
        isAnyOrderWager = true;
      }
      break;
    case 'RX_MATHPOS':
      boardData = mathPosToBoardData(board);
      wagerRowGenMode = rowGenMode.UNIQUE_ROWS;
      break;
    default:
      boardData = [];
      break;
  }
  const preserveReplacementOrder = isAnyOrderProduct || isAnyOrderWager;

  if (replacementRequired && wagerRowGenMode === rowGenMode.COMBINATIONS) {
    boardData = replaceInRow(boardData, cancelledParticipants, reserve, preserveReplacementOrder);
  }

  switch (wagerRowGenMode) {
    case rowGenMode.COMBINATIONS:
      rows = combinations(numSelections, boardData);
      break;
    case rowGenMode.PERMUTATIONS:
      rows = permutations(numSelections, boardData);
      break;
    case rowGenMode.UNIQUE_ROWS:
      {
        rows = buildUniqueRankRows(boardData, numSelections);
        break;
      }
    case rowGenMode.WHEEL:
      {
        const base = boardData[0];
        const extras = boardData[1];
        const numBase = base.length;
        const extraCombinations = combinations(numSelections - numBase, extras);
        rows = extraCombinations.map(row => [...base, ...row]);
        break;
      }
    default:
      rows = [];
      break;
  }

  if (replacementRequired && (wagerRowGenMode === rowGenMode.UNIQUE_ROWS || wagerRowGenMode === rowGenMode.PERMUTATIONS || wagerRowGenMode === rowGenMode.WHEEL)) {
    rows = replaceInRows(rows, cancelledParticipants, reserve, preserveReplacementOrder);
  }

  if (replacementRequired && preserveReplacementOrder) {
    for (let i = 0; i < rows.length; i++) {
      rows[i] = collapseRow(rows[i]);
    }
  }

  const hasResult = Boolean(resultRows);

  const rowObjects = new Array(rows.length);
  const conditions = gameTypeWindivs[gameType].conditions(isLegacyTopp5);
  const highlightRow = gameTypeWindivs[gameType].highlightRow;

  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    let partialRowObj;
    let windiv;
    let resultRowIndex;
    let numTotalCorrectIrrelevant = 0;
    if (hasResult) {
      partialRowObj = getRowValues(resultRows[0], rows[rowIndex]);
      windiv = getRowWindiv(partialRowObj, conditions);
      resultRowIndex = 0;

      for (let currentResultRowIndex = 1; currentResultRowIndex < resultRows.length; currentResultRowIndex++) {
        const newPartialRowObj = getRowValues(resultRows[currentResultRowIndex], rows[rowIndex]);
        const newWindiv = getRowWindiv(newPartialRowObj, conditions);

        if ((newWindiv < windiv || windiv === -1) && newWindiv > -1) {
          partialRowObj = newPartialRowObj;
          windiv = newWindiv;
          resultRowIndex = currentResultRowIndex;
        } else if (newWindiv === windiv) {
          const determinedBestRow = bestRow(conditions)(partialRowObj, newPartialRowObj);
          if (determinedBestRow) {
            partialRowObj = determinedBestRow;
            resultRowIndex = currentResultRowIndex;
          }
        }
      }

      partialRowObj.rowValues = getRowValuesWithIrrelevant(partialRowObj, wagerRowGenMode);
      numTotalCorrectIrrelevant = getNumTotalCorrectIrrelevant(partialRowObj);
    } else {
      partialRowObj = getRowValuesNoResult(rows[rowIndex]);
      windiv = -1;
      resultRowIndex = undefined;
    }
    const overallRowValue = getOverallRowValue(partialRowObj, windiv);

    const rowObj = buildCompleteRowObj(rowIndex, rows[rowIndex], partialRowObj, windiv, numTotalCorrectIrrelevant, highlightRow ? highlightRow[windiv] : false, overallRowValue, resultRows !== undefined, resultRowIndex);

    rowObjects[rowIndex] = rowObj;
  }

  const sortFunc = gameTypeHasSmygen(gameType, rowObjects.length) && !wagerExamined && selectedView === 'all' && isSmygen ? sort([byRow]) : sort(gameTypeWindivs[gameType].sorting);
  rowObjects.sort(sortFunc);
  return rowObjects;
};
setGlobal('svs.components.racing.resultUtils.wagerToRows', wagerToRows);

 })(window);