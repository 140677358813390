(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/combinatorics.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/combinatorics.js');
"use strict";


function permutations(k, list) {
  let partial = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const results = [];
  for (let i = 0; i < list.length; i++) {
    if (k > 1) {
      const listCopy = list.slice();
      listCopy.splice(i, 1);
      results.push(...permutations(k - 1, listCopy, partial.concat(list[i])));
    } else {
      results.push(partial.concat(list[i]));
    }
  }
  return results;
}

function combinations(k, list) {
  const results = [];
  let sub;
  let next;
  for (let i = 0; i < list.length; i++) {
    if (k === 1) {
      results.push([list[i]]);
    } else {
      sub = combinations(k - 1, list.slice(i + 1, list.length));
      for (let j = 0; j < sub.length; j++) {
        next = sub[j];
        next.unshift(list[i]);
        results.push(next);
      }
    }
  }
  return results;
}
setGlobal('svs.components.racing.resultUtils.combinatorics', {
  combinations,
  permutations
});

 })(window);