(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/windivs/gametype-windivs.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/windivs/gametype-windivs.js');
"use strict";


const gameTypes = svs.racing.common.constants.gameTypes;
const {
  inOrder,
  anyOrder,
  totalAnyOrder
} = svs.components.racing.resultUtils.windivConditions;
const {
  byWindiv,
  byActualRowLength,
  byNumTotalCorrect,
  byNumCorrectInOrder,
  byNumTotalCorrectInOrder,
  byNumCorrectAnyOrder,
  byNumTotalCorrectAnyOrder,
  byNumTotalCorrectIrrelevant,
  byRow
} = svs.components.racing.resultUtils.sorting;


const winDivs = {
  [gameTypes.TOPP5]: {
    conditions: function () {
      let isLegacyTopp5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      if (isLegacyTopp5) {
        return [inOrder(5, 5), anyOrder(5, 5), anyOrder(4, 5), anyOrder(3, 5), inOrder(4, 4), anyOrder(4, 4), anyOrder(3, 4)];
      }
      return [inOrder(5, 5), anyOrder(5, 5), totalAnyOrder(4, 5), anyOrder(3, 5), inOrder(4, 4), anyOrder(4, 4), anyOrder(3, 4)];
    },
    sorting: [byWindiv, byActualRowLength, byNumCorrectInOrder, byNumCorrectAnyOrder, byNumTotalCorrect, byNumTotalCorrectAnyOrder, byNumTotalCorrectInOrder, byNumTotalCorrectIrrelevant, byRow],
    labels: [() => "5 f\xF6rsta i m\xE5l i r\xE4tt ordning", () => "5 f\xF6rsta i m\xE5l utan ordning", _ref => {
      let {
        isLegacyTopp5
      } = _ref;
      const label = isLegacyTopp5 ? "4 f\xF6rsta i m\xE5l utan ordning" : '4 av 5 i mål utan ordning';
      return label;
    }, () => "3 f\xF6rsta i m\xE5l utan ordning", () => "4 f\xF6rsta i m\xE5l i r\xE4tt ordning",
    () => "4 f\xF6rsta i m\xE5l utan ordning",
    () => "3 f\xF6rsta i m\xE5l utan ordning" 
    ],
    labelCircle: (numCorrect, isWin, winDiv, isLegacyTopp5) => {
      if (winDiv === 2 && !isLegacyTopp5) {
        return ['4', 'rätt'];
      }
      if (isWin || numCorrect > 1) {
        return ["".concat(numCorrect), 'första'];
      }
      return ["".concat(numCorrect), 'rätt'];
    },
    highlightRow: [true, false, false, false, true, false, false],
    cancelledWinDivIndex: 4
  },
  [gameTypes.RX4IO]: {
    conditions: () => [inOrder(4, 4), anyOrder(4, 4), anyOrder(3, 4), anyOrder(3, 3)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectInOrder, byNumCorrectAnyOrder, byNumTotalCorrectInOrder, byNumTotalCorrectAnyOrder, byRow],
    labels: [() => "4 f\xF6rsta i m\xE5l i r\xE4tt ordning", () => "4 f\xF6rsta i m\xE5l utan ordning", () => "3 f\xF6rsta i m\xE5l utan ordning", () => "3 f\xF6rsta i m\xE5l utan ordning" 
    ],
    labelCircle: (numCorrect, isWin) => {
      if (isWin || numCorrect > 1) {
        return ["".concat(numCorrect), 'första'];
      }
      return ["".concat(numCorrect), 'rätt'];
    },
    highlightRow: [true, false, false, false],
    cancelledWinDivIndex: 3
  },
  [gameTypes.RX3IOAO]: {
    conditions: () => [inOrder(3, 3), anyOrder(3, 3), anyOrder(2, 2), anyOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectInOrder, byNumCorrectAnyOrder, byNumTotalCorrectInOrder, byNumTotalCorrectAnyOrder, byRow],
    labels: [() => "3 f\xF6rsta i m\xE5l i r\xE4tt ordning", () => "3 f\xF6rsta i m\xE5l utan ordning", () => "2 f\xF6rsta i m\xE5l utan ordning",
    () => "f\xF6rsta i m\xE5l" 
    ],
    labelCircle: (numCorrect, isWin) => {
      if (isWin || numCorrect > 1) {
        return ["".concat(numCorrect), 'första'];
      }
      return ["".concat(numCorrect), 'rätt'];
    },
    highlightRow: [true, false, false, false],
    cancelledWinDivIndex: 2
  },
  [gameTypes.RX3AO]: {
    conditions: () => [anyOrder(3, 3), anyOrder(2, 2), anyOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectAnyOrder, byNumTotalCorrectAnyOrder, byRow],
    labels: [_ref2 => {
      let {
        winners
      } = _ref2;
      return "R\xE4tt Trippel: ".concat(winners);
    }, _ref3 => {
      let {
        winners
      } = _ref3;
      return "R\xE4tt Trippel: ".concat(winners, " - STR");
    }, _ref4 => {
      let {
        winners
      } = _ref4;
      return "R\xE4tt Trippel: ".concat(winners, " - STR - STR");
    }],
    labelCircle: numCorrect => ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: 1
  },
  [gameTypes.TOPP2]: {
    conditions: () => [anyOrder(2, 2), anyOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectAnyOrder, byNumTotalCorrectAnyOrder, byRow],
    labels: [_ref5 => {
      let {
        winners
      } = _ref5;
      return "R\xE4tt Duo: ".concat(winners);
    }, _ref6 => {
      let {
        winners
      } = _ref6;
      return "R\xE4tt Duo: ".concat(winners, " - STR");
    } 
    ],
    labelCircle: (numCorrect, isWin) => isWin ? ['Duo'] : ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: 1
  },
  [gameTypes.RX2IO]: {
    conditions: () => [inOrder(2, 2), inOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectInOrder, byNumTotalCorrectInOrder, byRow],
    labels: [() => "2 f\xF6rsta i m\xE5l i r\xE4tt ordning", _ref7 => {
      let {
        winners
      } = _ref7;
      return "R\xE4tt Duo Rak: ".concat(winners, " - STR");
    }],
    labelCircle: numCorrect => ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: 1
  },
  [gameTypes.RX2P4]: {
    conditions: () => [anyOrder(2, 2), anyOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectAnyOrder, byNumTotalCorrectAnyOrder, byRow],
    labels: [_ref8 => {
      let {
        winners
      } = _ref8;
      return "R\xE4tt h\xE4star: ".concat(winners);
    }, _ref9 => {
      let {
        winners
      } = _ref9;
      return "R\xE4tt h\xE4star: ".concat(winners, " - STR");
    } 
    ],
    labelCircle: (numCorrect, isWin) => isWin ? ['2 av 4'] : ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: 1
  },
  [gameTypes.RX2P]: {
    conditions: () => [anyOrder(2, 2), anyOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectAnyOrder, byNumTotalCorrectAnyOrder, byRow],
    labels: [_ref10 => {
      let {
        winners
      } = _ref10;
      return "R\xE4tt h\xE4star: ".concat(winners);
    }, _ref11 => {
      let {
        winners
      } = _ref11;
      return "R\xE4tt h\xE4star: ".concat(winners, " - STR");
    } 
    ],
    labelCircle: (numCorrect, isWin) => isWin ? ['2 av 3'] : ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: 1
  },
  [gameTypes.VINNARE]: {
    conditions: () => [inOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byRow],
    labels: [_ref12 => {
      let {
        winners
      } = _ref12;
      return "R\xE4tt Vinnare: ".concat(winners);
    }],
    labelCircle: numCorrect => ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: -1
  },
  [gameTypes.PLATS]: {
    conditions: () => [anyOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byRow],
    labels: [_ref13 => {
      let {
        winners
      } = _ref13;
      return "R\xE4tt Plats: ".concat(winners);
    }],
    labelCircle: numCorrect => ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: -1
  },
  [gameTypes.RX3IO]: {
    conditions: () => [inOrder(3, 3), inOrder(2, 2), inOrder(1, 1)],
    sorting: [byWindiv, byActualRowLength, byNumCorrectInOrder, byNumTotalCorrectInOrder, byRow],
    labels: [() => "3 f\xF6rsta i m\xE5l i r\xE4tt ordning", _ref14 => {
      let {
        winners
      } = _ref14;
      return "R\xE4tt Trippel Rak: ".concat(winners, " - STR");
    }, _ref15 => {
      let {
        winners
      } = _ref15;
      return "R\xE4tt Trippel Rak: ".concat(winners, " - STR");
    }],
    labelCircle: numCorrect => ["".concat(numCorrect), 'rätt'],
    cancelledWinDivIndex: 1
  }
};
setGlobal('svs.components.racing.resultUtils.gameTypeWindivs', winDivs);

 })(window);