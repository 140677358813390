(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/windivs/windiv-conditions.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/windivs/windiv-conditions.js');
"use strict";


const validateRowLength = (numCorrect, rowLength, partialRowObj) => {
  if (rowLength !== partialRowObj.actualRowLength) {
    return false;
  }
  if (numCorrect > partialRowObj.actualRowLength) {
    return false;
  }
  return true;
};
const inOrder = (numCorrect, rowLength) => partialRowObj => {
  if (validateRowLength(numCorrect, rowLength, partialRowObj)) {
    return partialRowObj.numCorrectInOrder >= numCorrect;
  }
  return false;
};
const anyOrder = (numCorrect, rowLength) => partialRowObj => {
  if (validateRowLength(numCorrect, rowLength, partialRowObj)) {
    return partialRowObj.numCorrectAnyOrder >= numCorrect;
  }
  return false;
};

const totalAnyOrder = (numCorrect, rowLength) => partialRowObj => {
  if (validateRowLength(numCorrect, rowLength, partialRowObj)) {
    return partialRowObj.numTotalCorrectAnyOrder + partialRowObj.numUnknown >= numCorrect;
  }
  return false;
};
setGlobal('svs.components.racing.resultUtils.windivConditions', {
  anyOrder,
  inOrder,
  totalAnyOrder
});

 })(window);