(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/use-result-row.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/use-result-row.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  useMemo,
  useState,
  useEffect
} = React;
const {
  gameTypes,
  resultPlaceholders
} = svs.racing.common.constants;
const {
  wagerToRows,
  myGamingUtil,
  gameTypeWindivs
} = svs.components.racing.resultUtils;
const {
  getRowValues,
  getRowWindiv,
  getRowValuesWithIrrelevant,
  getNumTotalCorrectIrrelevant
} = svs.components.racing.resultUtils.valuation;
const {
  getCancelledParticipants
} = svs.racing.common.util;
const buildUniqueRankRows = svs.racing.build_unique_rank_row.buildUniqueRankRows;
const {
  sort
} = svs.components.racing.resultUtils.sorting;
const {
  useNavigationState
} = svs.racing.navigation;
const {
  gameTypeHasSmygen,
  getIsLegacyTopp5
} = svs.components.racing.resultUtils.myGamingUtil;

const bestPossibleRowCalc = (result, row, resultIndexExamined) => {
  const bestRow = row;
  const bestPossibleRow = [...result];
  for (let i = 0; i < resultIndexExamined.length; i++) {
    const examinedIndex = resultIndexExamined[i];
    const examinedValue = result[examinedIndex];
    const examinedValueInBestRowIndex = bestRow.indexOf(examinedValue);
    if (examinedValueInBestRowIndex > -1) {
      if (examinedValueInBestRowIndex === examinedIndex) {
      } else {
        const bestRowValue = bestRow[examinedValueInBestRowIndex];
        const potentialSwapIndex = bestPossibleRow.indexOf(bestRowValue);
        if (potentialSwapIndex > -1) {
          bestPossibleRow[potentialSwapIndex] = bestPossibleRow[examinedValueInBestRowIndex];
        }
        bestPossibleRow[examinedValueInBestRowIndex] = bestRowValue;
      }
    } else {
      const indexBestPossibleRowMatch = bestPossibleRow.indexOf(examinedValue);
      let uselessValueInBestRowIndex = -1;
      for (let j = bestRow.length - 1; j >= 0; j--) {
        if (result.indexOf(bestRow[j]) < 0 && bestPossibleRow.indexOf(bestRow[j]) < 0) {
          uselessValueInBestRowIndex = j;
          break;
        }
      }
      const prevValue = bestPossibleRow[uselessValueInBestRowIndex];
      bestPossibleRow[uselessValueInBestRowIndex] = bestRow[uselessValueInBestRowIndex];
      if (uselessValueInBestRowIndex !== indexBestPossibleRowMatch) {
        bestPossibleRow[indexBestPossibleRowMatch] = prevValue;
      }
    }
  }
  return bestPossibleRow;
};
const useResultRow = (wager, race, selectedView, isSmygen) => {
  const wagerGameType = useMemo(() => {
    if (wager) {
      return myGamingUtil.getWagerGameType(wager);
    }
    return undefined;
  }, [wager]);
  const isLegacyTopp5 = useMemo(() => getIsLegacyTopp5(wager, race), [wager, race]);
  const result = useMemo(() => {
    if (wager && wager.completed && race && race.participants) {
      return myGamingUtil.getResult(race.participants, wagerGameType);
    }
    return undefined;
  }, [wager, race, wagerGameType]);
  const resultRows = useMemo(() => result ? buildUniqueRankRows(result, result.length) : undefined, [result]);
  const cancelledParticipants = useMemo(() => {
    if (race && race.participants) {
      return getCancelledParticipants(race.participants);
    }
    return undefined;
  }, [race]);
  const navState = useNavigationState();
  const [rowObjects, setRowObjects] = useState(undefined);
  const [initialRowObjects, setIntialRowObjects] = useState(undefined);
  const [resultIndexExamined, setResultIndexExamined] = useState([]);
  const [wagerRowExamined, setWagerRowExamined] = useState([]);
  useEffect(() => {
    if (wager && cancelledParticipants) {
      const resultingRows = wagerToRows(wager.wager, resultRows, cancelledParticipants, navState.wagerExamined, selectedView, isSmygen, isLegacyTopp5);
      setRowObjects(resultingRows);
      setIntialRowObjects(resultingRows);
    }
  }, [wager, resultRows, cancelledParticipants, selectedView, isSmygen]);
  const resultRowObject = useMemo(() => {
    if (result && rowObjects && wager) {
      return myGamingUtil.getResultRowObject(result, rowObjects, wagerGameType, isLegacyTopp5);
    }
  }, [result, rowObjects, wager, wagerGameType]);
  const setWagerRowExaminedOnce = index => {
    var _resultRowObject$row;
    const resultingRow = resultRowObject === null || resultRowObject === void 0 || (_resultRowObject$row = resultRowObject.row) === null || _resultRowObject$row === void 0 ? void 0 : _resultRowObject$row.flat();
    if (resultIndexExamined.indexOf(index) < 0) {
      setResultIndexExamined([...resultIndexExamined, index]);
    }
    if (wagerRowExamined.indexOf(resultingRow[index]) < 0 && resultingRow[index] < 100) {
      setWagerRowExamined([...wagerRowExamined, ...resultRowObject.row[index]]);
    }
    setRowValues([...resultIndexExamined, index]);
  };
  const bestPossibleWinDiv = useMemo(() => {
    if (!rowObjects || !result || !resultIndexExamined || !gameTypeHasSmygen(myGamingUtil.getWagerGameType(wager))) {
      return undefined;
    }
    const selectedResultRow = resultRows[rowObjects[0].resultRowIndex];
    if (selectedResultRow === undefined || (selectedResultRow === null || selectedResultRow === void 0 ? void 0 : selectedResultRow.length) < 1) {
      return undefined;
    }
    const bestPossibleRow = bestPossibleRowCalc(selectedResultRow, rowObjects[0].row, resultIndexExamined);
    const partialRowObj = getRowValues(selectedResultRow, bestPossibleRow);
    const winDiv = getRowWindiv(partialRowObj, gameTypeWindivs[wagerGameType].conditions(isLegacyTopp5));
    return {
      winDiv
    };
  }, [rowObjects, result, resultIndexExamined, resultRows, wager, wagerGameType]);
  const setRowValues = resIndexExamined => {
    if (resIndexExamined.length === 5) {
      var _gameTypeWindivs$game;
      setRowObjects(initialRowObjects.sort(sort((_gameTypeWindivs$game = gameTypeWindivs[gameTypes.TOPP5]) === null || _gameTypeWindivs$game === void 0 ? void 0 : _gameTypeWindivs$game.sorting)));
      return false;
    }
    if (rowObjects && rowObjects.length && resultRows) {
      var _gameTypeWindivs$game2;
      let potentialRowObjects = rowObjects.map(row => {
        if (row.resultRowIndex >= 0) {
          const selectedResultRow = resultRows[row.resultRowIndex];
          const currentPossibleResultRow = selectedResultRow.map((srRow, i) => {
            if (resIndexExamined.indexOf(i) > -1) {
              return srRow;
            }
            return i + resultPlaceholders.FAKE_HORSE_NUM;
          });
          const bestPossibleRow = bestPossibleRowCalc(resultRows[row.resultRowIndex], row.row, resIndexExamined);
          const partialRowObj = getRowValues(currentPossibleResultRow, row.row);
          const {
            rowValues: bestPossibleRowValues,
            resultToRowIndices: bestPossibleResultToRowIndices
          } = getRowValues(selectedResultRow, bestPossibleRow);
          const bestPartialRowObj = _objectSpread(_objectSpread({}, partialRowObj), {}, {
            rowValues: bestPossibleRowValues,
            resultToRowIndices: bestPossibleResultToRowIndices,
            resultRowUsed: resultRows[row.resultRowIndex]
          });
          const winDiv = getRowWindiv(partialRowObj, gameTypeWindivs[wagerGameType].conditions(isLegacyTopp5));
          const newPartialRowObj = _objectSpread(_objectSpread(_objectSpread({}, row), bestPartialRowObj), {}, {
            windiv: winDiv
          });
          return _objectSpread(_objectSpread({}, newPartialRowObj), {}, {
            rowValues: getRowValuesWithIrrelevant(newPartialRowObj)
          });
        }
        return row;
      });
      potentialRowObjects = potentialRowObjects.map(potentialRowObject => _objectSpread(_objectSpread({}, potentialRowObject), {}, {
        numTotalCorrectIrrelevant: getNumTotalCorrectIrrelevant(potentialRowObject)
      }));
      setRowObjects(potentialRowObjects.sort(sort((_gameTypeWindivs$game2 = gameTypeWindivs[gameTypes.TOPP5]) === null || _gameTypeWindivs$game2 === void 0 ? void 0 : _gameTypeWindivs$game2.sorting)));
    }
  };
  return {
    resultRowObject,
    rowObjects,
    bestPossibleWinDiv,
    wagerRowIndexExamined: resultIndexExamined,
    setWagerRowIndexExamined: setResultIndexExamined,
    setWagerRowExaminedOnce,
    wagerRowExamined,
    setWagerRowExamined
  };
};
setGlobal('svs.components.racing.resultUtils.bestPossibleRowCalc', bestPossibleRowCalc);
setGlobal('svs.components.racing.resultUtils.useResultRow', useResultRow);

 })(window);