(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/my-gaming-util.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/my-gaming-util.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  gameTypes,
  rowValues,
  subProductIdToGameType
} = svs.racing.common.constants;
const {
  util: {
    gameTypeNumResults
  }
} = svs.racing.common;
const buildUniqueRankRows = svs.racing.build_unique_rank_row.buildUniqueRankRows;
const {
  getRowValues,
  getRowWindiv
} = svs.components.racing.resultUtils.valuation;
const {
  sort
} = svs.components.racing.resultUtils.sorting;
const {
  gameTypeWindivs
} = svs.components.racing.resultUtils;

const getResult = (participants, gameType) => {
  let numPositions = 1;
  switch (gameType) {
    case gameTypes.TOPP5:
      numPositions = 5;
      break;
    case gameTypes.RX4IO:
      numPositions = 4;
      break;
    case gameTypes.RX2P4:
      numPositions = 4;
      break;
    case gameTypes.RX3IOAO:
    case gameTypes.RX3AO:
      numPositions = 3;
      break;
    case gameTypes.RX2P:
    case gameTypes.RX3IO:
      numPositions = 3;
      break;
    case gameTypes.TOPP2:
      numPositions = 2;
      break;
    case gameTypes.RX2IO:
      numPositions = 2;
      break;
    case gameTypes.VINNARE:
      numPositions = 1;
      break;
    case gameTypes.PLATS:
      numPositions = participants.length < 8 ? 2 : 3;
      break;
    default:
      break;
  }
  const result = new Array(numPositions);
  let totalPositions = 0;

  for (let positionIndex = 0; positionIndex < numPositions; positionIndex++) {
    result[positionIndex] = participants.filter(participant => participant.participantResult && participant.participantResult.finishPlace === positionIndex + 1).map(participant => participant.participantNumber);
    totalPositions += result[positionIndex].length;
  }
  if (totalPositions < numPositions) {
    return undefined;
  }

  for (let i = 1; i < result.length; i++) {
    const position = result[i];
    if (position.length === 0) {
      const previousPosition = result[i - 1];
      result[i] = previousPosition;
    }
  }
  return result;
};

const getResultRowObject = (result, rowObjects, gameType, isLegacyTopp5) => {
  switch (gameType) {
    case gameTypes.PLATS:
      {
        let numCorrect = 0;
        const values = result.map(resultPosition => {
          for (let i = 0; i < rowObjects.length; i++) {
            if (resultPosition.some(pos => rowObjects[i].row.indexOf(pos) > -1)) {
              numCorrect++;
              return rowValues.CORRECT;
            }
          }
          return rowValues.INCORRECT;
        });
        return _objectSpread(_objectSpread({}, rowObjects[0]), {}, {
          row: [...result],
          rowValues: values,
          numCorrectAnyOrder: numCorrect,
          isHighlighted: false
        });
      }
    case gameTypes.RX2P4:
    case gameTypes.RX2P:
      {
        const bestRowValues = new Array(result.length).fill(rowValues.INCORRECT);

        for (let i = 0; i < rowObjects.length; i++) {
          for (let j = 0; j < rowObjects[i].resultToRowIndices.length; j++) {
            const resultToRowIndex = rowObjects[i].resultToRowIndices[j];
            const currentRowValue = rowObjects[i].rowValues[resultToRowIndex];
            const currentBestRowValue = bestRowValues[j];
            if (currentRowValue > currentBestRowValue) {
              bestRowValues[j] = currentRowValue;
            }
          }
        }
        return _objectSpread(_objectSpread({}, rowObjects[0]), {}, {
          row: [...result],
          rowValues: bestRowValues,
          isHighlighted: false
        });
      }
    case gameTypes.TOPP5:
    case gameTypes.RX4IO:
    case gameTypes.RX3IOAO:
    case gameTypes.RX3AO:
    case gameTypes.TOPP2:
    case gameTypes.RX2IO:
    case gameTypes.VINNARE:
    case gameTypes.RX3IO:
    default:
      {
        const resultRows = buildUniqueRankRows(result, gameTypeNumResults(gameType));
        const bestRows = new Array(resultRows.length);
        for (let i = 0; i < resultRows.length; i++) {
          const partialRowObj = getRowValues(rowObjects[0].row, resultRows[i]);
          for (let j = 0; j < partialRowObj.rowValues.length; j++) {
            const foundIndex = rowObjects[0].row.findIndex(value => value === resultRows[i][j]);
            partialRowObj.rowValues[j] = foundIndex >= 0 ? rowObjects[0].rowValues[foundIndex] : rowValues.INCORRECT;
          }
          bestRows[i] = _objectSpread(_objectSpread({}, partialRowObj), {}, {
            row: resultRows[i],
            windiv: getRowWindiv(partialRowObj, gameTypeWindivs[gameType].conditions(isLegacyTopp5))
          });
        }
        if (bestRows.length > 1) {
          bestRows.sort(sort(gameTypeWindivs[gameType].sorting));
        }
        return _objectSpread(_objectSpread({}, rowObjects[0]), {}, {
          bestRow: [...rowObjects[0].row],
          row: result,
          rowValues: bestRows[0].rowValues,
          isHighlighted: false
        });
      }
  }
};

const getWagerGameType = wager => {
  const productId = wager.wager.bets[0].subProductId;
  return subProductIdToGameType[productId];
};
const getIsLegacyTopp5 = (wager, race) => {
  if (!wager || !race || !(race !== null && race !== void 0 && race.startTime)) {
    return false;
  }
  const gameType = getWagerGameType(wager);
  if (gameType !== gameTypes.TOPP5) {
    return false;
  }
  return dateFns.isBefore(new Date(race.startTime), new Date(2023, 10, 4));
};

const gameTypeHasSmygen = (gameType, length) => {
  const ROW_ANIMATION_LIMIT = 1000;
  if (gameType === gameTypes.TOPP5) {
    if (length) {
      if (length < ROW_ANIMATION_LIMIT) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
};
setGlobal('svs.components.racing.resultUtils.myGamingUtil', {
  getResult,
  getResultRowObject,
  getWagerGameType,
  gameTypeHasSmygen,
  getIsLegacyTopp5
});

 })(window);