(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/valuation.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/valuation.js');
"use strict";


const {
  rowValues,
  rowGenMode,
  resultPlaceholders
} = svs.racing.common.constants;

const getPositionIndexValue = (index, correctRow, singleRow) => {
  if (!singleRow[index]) {
    return {
      index: undefined,
      value: rowValues.MISSING
    };
  }
  if (singleRow[index] === correctRow[index]) {
    return {
      index,
      value: rowValues.CORRECT_IN_ORDER
    };
  }
  const correctRowIndex = correctRow.indexOf(singleRow[index]);
  return {
    index: correctRowIndex > -1 ? correctRowIndex : undefined,
    value: correctRowIndex > -1 ? rowValues.CORRECT : rowValues.INCORRECT
  };
};
const getPositionIsMissing = position => {
  if (!position) {
    return rowValues.MISSING;
  }
  return rowValues.NEUTRAL;
};

const getRowValues = (correctRow, singleRow) => {
  const values = new Array(singleRow.length);
  const resultToRowIndices = new Array(correctRow.length).fill(undefined);
  let numCorrectInOrder = 0;
  let numTotalCorrectInOrder = 0;
  let numCorrectAnyOrder = 0;
  let numTotalCorrectAnyOrder = 0;
  let actualRowLength = singleRow.length;
  const isUnbalanced = correctRow.length !== singleRow.length;

  let numUnknown = 0;
  for (let i = 0; i < correctRow.length; i++) {
    if (correctRow[i] >= resultPlaceholders.FAKE_HORSE_NUM) {
      numUnknown++;
    }
  }
  for (let i = 0; i < singleRow.length; i++) {
    const position = getPositionIndexValue(i, correctRow, singleRow);
    if (position.value === rowValues.CORRECT_IN_ORDER) {
      numTotalCorrectInOrder++;
      if (i === numCorrectInOrder) {
        numCorrectInOrder++;
      }
    }
    if (position.index !== undefined && (position.value === rowValues.CORRECT_IN_ORDER || position.value === rowValues.CORRECT)) {
      resultToRowIndices[position.index] = i;
      numTotalCorrectAnyOrder++;
    }
    if (position.value === rowValues.MISSING) {
      actualRowLength--;
    }
    values[i] = position.value;
  }
  let fromStart = 0;
  for (let i = 0; i < resultToRowIndices.length; i++) {
    if (resultToRowIndices[i] !== undefined) {
      if (fromStart === i) {
        numCorrectAnyOrder++;
      } else if (isUnbalanced) {
        numCorrectAnyOrder++;
      }
      fromStart++;
    }
  }
  return {
    rowValues: values,
    numCorrectInOrder,
    numTotalCorrectInOrder,
    numCorrectAnyOrder,
    numTotalCorrectAnyOrder,
    numUnknown,
    actualRowLength,
    resultToRowIndices
  };
};
const getRowValuesNoResult = singleRow => {
  const values = new Array(singleRow.length);
  let actualRowLength = singleRow.length;
  for (let i = 0; i < singleRow.length; i++) {
    const position = getPositionIsMissing(singleRow[i]);
    if (position.value === rowValues.MISSING) {
      actualRowLength--;
    }
    values[i] = position.value;
  }
  return {
    rowValues: values,
    actualRowLength
  };
};
const getRowWindiv = (partialRowObj, conditions) => {
  for (let i = 0; i < conditions.length; i++) {
    if (conditions[i](partialRowObj)) {
      return i;
    }
  }
  return -1;
};
const getOverallRowValue = (partialRowObj, windiv) => {
  if (windiv < 0) {
    return rowValues.INCORRECT;
  }
  if (partialRowObj.numCorrectInOrder === partialRowObj.actualRowLength) {
    return rowValues.CORRECT_IN_ORDER;
  }
  return rowValues.CORRECT;
};

const getRowValuesWithIrrelevant = (partialRowObj, selectedRowGenMode) => {
  let fromStart = 0;
  switch (selectedRowGenMode) {
    case rowGenMode.WHEEL:
      {
        return partialRowObj.rowValues.map((value, index) => {
          if (value > rowValues.INCORRECT) {
            if (fromStart !== index) {
              return value === rowValues.CORRECT ? rowValues.CORRECT_IRRELEVANT_AO : rowValues.CORRECT_IRRELEVANT_IO;
            }
            fromStart++;
          }
          return value;
        });
      }
    case rowGenMode.COMBINATIONS:
    case rowGenMode.PERMUTATIONS:
    case rowGenMode.UNIQUE_ROWS:
    default:
      {
        const newRowValues = [...partialRowObj.rowValues];
        for (let i = 0; i < partialRowObj.resultToRowIndices.length; i++) {
          const currentResultToRowIndex = partialRowObj.resultToRowIndices[i];
          if (currentResultToRowIndex !== undefined) {
            if (fromStart !== i) {
              const currentRowValue = newRowValues[currentResultToRowIndex];
              newRowValues[currentResultToRowIndex] = currentRowValue === rowValues.CORRECT ? rowValues.CORRECT_IRRELEVANT_AO : rowValues.CORRECT_IRRELEVANT_IO;
            }
            fromStart++;
          }
        }
        return newRowValues;
      }
  }
};

const getNumTotalCorrectIrrelevant = partialRowObj => {
  let numTotalCorrectIrrelevant = 0;
  const newLocal = 0;
  for (let i = newLocal; i < partialRowObj.rowValues.length; i++) {
    if (partialRowObj.rowValues[i] > rowValues.CORRECT) {
      numTotalCorrectIrrelevant++;
    }
  }
  return numTotalCorrectIrrelevant;
};
setGlobal('svs.components.racing.resultUtils.valuation', {
  getPositionIndexValue,
  getRowValues,
  getRowValuesNoResult,
  getRowWindiv,
  getOverallRowValue,
  getRowValuesWithIrrelevant,
  getNumTotalCorrectIrrelevant
});

 })(window);