(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/sorting.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/sorting.js');
"use strict";



const compare = (a, b) => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  }
  return 0;
};
const compareRow = (rowA, rowB) => {
  for (let i = 0; i < rowA.length; i++) {
    const result = compare(rowA[i], rowB[i]);
    if (result !== 0) {
      return result;
    }
  }
  return 0;
};
const NO_WIN_WINDIV = 100;

const byWindiv = (rowObjectA, rowObjectB) => compare(rowObjectA.windiv < 0 ? NO_WIN_WINDIV : rowObjectA.windiv, rowObjectB.windiv < 0 ? NO_WIN_WINDIV : rowObjectB.windiv);
const byActualRowLength = (rowObjectA, rowObjectB) => compare(-rowObjectA.actualRowLength, -rowObjectB.actualRowLength);
const byNumCorrectInOrder = (rowObjectA, rowObjectB) => {
  if (rowObjectA.windiv === rowObjectB.windiv && rowObjectA.windiv === NO_WIN_WINDIV) {
    return 0;
  }
  return compare(-rowObjectA.numCorrectInOrder, -rowObjectB.numCorrectInOrder);
};
const byNumTotalCorrectInOrder = (rowObjectA, rowObjectB) => compare(-rowObjectA.numTotalCorrectInOrder, -rowObjectB.numTotalCorrectInOrder);
const byNumCorrectAnyOrder = (rowObjectA, rowObjectB) => {
  if (rowObjectA.windiv === rowObjectB.windiv && rowObjectA.windiv === NO_WIN_WINDIV) {
    return 0;
  }
  return compare(-rowObjectA.numCorrectAnyOrder, -rowObjectB.numCorrectAnyOrder);
};
const byNumTotalCorrectAnyOrder = (rowObjectA, rowObjectB) => compare(-rowObjectA.numTotalCorrectAnyOrder, -rowObjectB.numTotalCorrectAnyOrder);

const byRow = (rowObjectA, rowObjectB) => compareRow(rowObjectA.row, rowObjectB.row);
const byNumTotalCorrectBoth = (rowObjectA, rowObjectB) => compare(-(rowObjectA.numTotalCorrectAnyOrder - rowObjectA.numTotalCorrectInOrder), -(rowObjectB.numTotalCorrectAnyOrder - rowObjectB.numTotalCorrectInOrder));
const byNumTotalCorrectIrrelevant = (rowObjectA, rowObjectB) => compare(rowObjectA.numTotalCorrectIrrelevant, rowObjectB.numTotalCorrectIrrelevant);
const byNumTotalCorrect = (rowObjectA, rowObjectB) => compare(-(rowObjectA.numTotalCorrectAnyOrder - rowObjectA.numTotalCorrectIrrelevant), -(rowObjectB.numTotalCorrectAnyOrder - rowObjectB.numTotalCorrectIrrelevant));

const sort = conditions => (rowObjectA, rowObjectB) => {
  for (let i = 0; i < conditions.length; i++) {
    const result = conditions[i](rowObjectA, rowObjectB);
    if (result !== 0) {
      return result;
    }
  }
  return 0;
};
const bestRow = conditions => (rowObjectA, rowObjectB) => {
  for (let i = 0; i < conditions.length; i++) {
    const result = conditions[i](rowObjectA, rowObjectB);
    if (result < 0) {
      return rowObjectA;
    } else if (result > 0) {
      return rowObjectB;
    }
  }
  return undefined;
};
setGlobal('svs.components.racing.resultUtils.sorting', {
  byWindiv,
  byActualRowLength,
  byNumCorrectInOrder,
  byNumTotalCorrectInOrder,
  byNumCorrectAnyOrder,
  byNumTotalCorrect,
  byNumTotalCorrectAnyOrder,
  byNumTotalCorrectBoth,
  byNumTotalCorrectIrrelevant,
  byRow,
  sort,
  bestRow
});

 })(window);