(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/result-utils/assets/javascripts/get-circle-text.js') >= 0) return;  svs.modules.push('/components/racing/result-utils/assets/javascripts/get-circle-text.js');
"use strict";


const {
  gameTypeWindivs,
  myGamingUtil
} = svs.components.racing.resultUtils;
const {
  gameTypeIsInOrder,
  gameTypeIsExclusiveInOrder
} = svs.racing.common.util;
const {
  CurrencyFromJupiterString
} = svs.utils.format;
const getCircleText = (wager, resultRowObject, isLegacyTopp5) => {
  const isWin = CurrencyFromJupiterString(wager.winAmount) > 0;
  let circleText;
  if (resultRowObject) {
    const gameType = myGamingUtil.getWagerGameType(wager);
    const isInOrder = gameTypeIsInOrder(gameType);
    let circleTextNum = isInOrder ? Math.max(resultRowObject.numCorrectInOrder, resultRowObject.numCorrectAnyOrder) : Math.max(resultRowObject.numTotalCorrectInOrder, resultRowObject.numTotalCorrectAnyOrder);
    if (gameTypeIsExclusiveInOrder(gameType)) {
      circleTextNum = Math.max(resultRowObject.numTotalCorrectInOrder, resultRowObject.numCorrectInOrder);
    }
    circleText = gameTypeWindivs[gameType].labelCircle(circleTextNum, isWin, resultRowObject.windiv, isLegacyTopp5);
  } else {
    circleText = ['0', 'rätt'];
  }
  return circleText;
};
setGlobal('svs.components.racing.resultUtils.getCircleText', getCircleText);

 })(window);